import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import { ApprovalStatus, CurrentUserRole, IClinic } from 'src/models/app.models';
import { sendAzureEvent } from 'src/helpers/appInsights';
import { setActivePage, setShouldGetPendingPatientsAndClinicalWorkersCounts } from 'src/stores/appStore/appActions';
import { RootStateType } from 'src/stores/store';
import { isPauseItEnabled } from 'src/helpers/featureToggles';
import { getClinics } from 'src/helpers/services/Clinics';
import { getPendingPatientsAndClinicalWorkersCounts } from 'src/helpers/services/PendingPatientsAndClinicalWorkersCounts';
import { getRuntimeConfig } from 'src/helpers/envReader';
import { getSidebarItems } from 'src/helpers/sidebarItems';
import { RoutePath } from 'src/models/table.models';

import PendingUsersCount from './PendingUsersCount';

interface ISidebarList {
    title: string;
    type: string;
    analyticsEventName: string;
    disabled?: boolean;
    link?: string;
    className?: string;
    activeClassName?: string;
}

interface ISidebarItem {
    title: string;
    items: ISidebarList[];
}

interface IPendingPatientsAndClinicalWorkersCounts {
    pendingClinicalWorkersCount: number | null;
    pendingPatientsCount: number | null;
}

const isProduction = getRuntimeConfig('REACT_APP_IS_PRODUCTION') === 'true';

export const bemBlockName = 'sidebar';

const Sidebar = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const activePathname = location.pathname;

    const [clinics, setClinics] = useState<IClinic[] | null>(null);
    const [pendingPatientsAndClinicalWorkersCounts, setPendingPatientsAndClinicalWorkersCounts] =
        useState<IPendingPatientsAndClinicalWorkersCounts>({
            pendingClinicalWorkersCount: null,
            pendingPatientsCount: null,
        });
    const [isPendingPatientsAndClinicalWorkersCountsWithError, setIsPendingPatientsAndClinicalWorkersCountsWithError] =
        useState(false);

    const { currentUser, shouldGetPendingPatientsAndClinicalWorkersCounts } = useSelector((state: RootStateType) => ({
        currentUser: state.appState.currentUser,
        shouldGetPendingPatientsAndClinicalWorkersCounts:
            state.appState.shouldGetPendingPatientsAndClinicalWorkersCounts,
    }));

    const hasMounted = useRef(false);

    const isPauseItDisabledAndCurrentUserIsApprovedClinicAdmin =
        !isPauseItEnabled() &&
        currentUser?.role === CurrentUserRole.ClinicAdmin &&
        currentUser?.approvalStatus === ApprovalStatus.Approved;

    const loadClinics = async () => {
        const res = await getClinics();

        setClinics(res);
    };

    const loadPendingPatientsAndClinicalWorkersCounts = async () => {
        let newPendingPatientsAndClinicalWorkersCounts;

        try {
            newPendingPatientsAndClinicalWorkersCounts = await getPendingPatientsAndClinicalWorkersCounts();
        } catch {
            setIsPendingPatientsAndClinicalWorkersCountsWithError(true);
            return;
        }

        setIsPendingPatientsAndClinicalWorkersCountsWithError(false);

        setPendingPatientsAndClinicalWorkersCounts({
            pendingPatientsCount: newPendingPatientsAndClinicalWorkersCounts?.pendingPatientsCount,
            pendingClinicalWorkersCount: newPendingPatientsAndClinicalWorkersCounts?.pendingClinicalWorkersCount,
        });
    };

    useEffect(() => {
        if (!isPauseItDisabledAndCurrentUserIsApprovedClinicAdmin) {
            return;
        }

        if (!hasMounted.current) {
            loadPendingPatientsAndClinicalWorkersCounts();
            hasMounted.current = true;
        }

        if (shouldGetPendingPatientsAndClinicalWorkersCounts) {
            loadPendingPatientsAndClinicalWorkersCounts();
            dispatch(setShouldGetPendingPatientsAndClinicalWorkersCounts(false));
        }
    }, [
        dispatch,
        isPauseItDisabledAndCurrentUserIsApprovedClinicAdmin,
        shouldGetPendingPatientsAndClinicalWorkersCounts,
    ]);

    useEffect(() => {
        if (isPauseItEnabled() && currentUser?.role === CurrentUserRole.SuperAdmin) {
            loadClinics();
        }
    }, [currentUser?.role]);

    const items: ISidebarItem[] = useMemo(() => getSidebarItems(currentUser, clinics), [currentUser, clinics]);

    const changeActivePage = (pageType: string, analyticsEventName: string, link?: string) => {
        if (link) {
            sendAzureEvent(analyticsEventName, { link: `${link}` });
            window.open(link);

            return;
        }

        if (
            (isPauseItDisabledAndCurrentUserIsApprovedClinicAdmin && pageType === RoutePath.adminPendingPatients) ||
            pageType === RoutePath.adminPendingProviders
        ) {
            loadPendingPatientsAndClinicalWorkersCounts();
        }

        sendAzureEvent(analyticsEventName);
        dispatch(setActivePage(''));
        history.push(pageType);
    };

    return (
        <div className={bemBlockName}>
            {!isProduction && (
                <div className={`${bemBlockName}__non-production-label`}>
                    <div>{t('sidebar.nonProductionUse')}</div>
                </div>
            )}

            {items.map((item, index) => (
                <ul
                    key={uuidv4()}
                    className={clsx(`${bemBlockName}__list`, { first: index === 0 })}
                    data-testid={`sidebarSection-${index}`}
                >
                    <div className={`${bemBlockName}__list-title`} data-testid={`sidebarTitle-${index}`}>
                        {t(item.title)}
                    </div>

                    {item.items.map((listItem, i) => (
                        <li
                            key={uuidv4()}
                            role="menuitem"
                            onClick={() => changeActivePage(listItem.type, listItem.analyticsEventName, listItem?.link)}
                            data-testid={`sidebarItem-${i}`}
                            className={clsx(`${bemBlockName}__list-item`, listItem.className, {
                                disabled: listItem.disabled,
                                active: activePathname === listItem.type,
                                [`${listItem.activeClassName}`]: activePathname === listItem.type,
                            })}
                            aria-hidden="true"
                        >
                            {t(listItem.title)}

                            {listItem.type === RoutePath.adminPendingPatients && (
                                <PendingUsersCount
                                    count={pendingPatientsAndClinicalWorkersCounts.pendingPatientsCount}
                                    error={isPendingPatientsAndClinicalWorkersCountsWithError}
                                />
                            )}

                            {listItem.type === RoutePath.adminPendingProviders && (
                                <PendingUsersCount
                                    count={pendingPatientsAndClinicalWorkersCounts.pendingClinicalWorkersCount}
                                    error={isPendingPatientsAndClinicalWorkersCountsWithError}
                                />
                            )}
                        </li>
                    ))}
                </ul>
            ))}
        </div>
    );
};

export default Sidebar;
