import { IOktaData } from 'src/helpers/parseOktaData';
import { AgreementStatus, IUser } from 'src/models/app.models';

import ActionTypes from '../actionTypes';

// TODO: refactor setActivePage, it's now only being used for error pages.
export function setActivePage(page: string) {
    return {
        type: ActionTypes.SET_ACTIVE_PAGE,
        payload: page,
    } as const;
}

export function setClinicId(clinicId: string) {
    return {
        type: ActionTypes.SET_CLINIC_ID,
        payload: clinicId,
    } as const;
}

// TODO: Change IUser to ICurrentUser. Also, maybe consider removing "I" prefix for all our models.
export function setCurrentUser(currentUser: IUser) {
    return {
        type: ActionTypes.SET_CURRENT_USER,
        payload: currentUser,
    } as const;
}

// TODO: remove setRole and use currentUser.
export function setRole(role: string) {
    return {
        type: ActionTypes.SET_ROLE,
        payload: role,
    } as const;
}

export function saveOktaData(oktaData: IOktaData) {
    return {
        type: ActionTypes.SAVE_OKTA_DATA,
        payload: oktaData,
    } as const;
}

export function setAgreementStatus(status: AgreementStatus) {
    return {
        type: ActionTypes.SET_AGREEMENT_STATUS,
        payload: status,
    } as const;
}

export function setPdmUploadFailCount(count: number) {
    return {
        type: ActionTypes.INCREMENT_PDM_UPLOAD_FAIL_COUNT,
        payload: count,
    } as const;
}

export function setRowsPerPage(rowsPerPage: number) {
    return {
        type: ActionTypes.ROWS_PER_PAGE,
        payload: rowsPerPage,
    } as const;
}

export function setSignInUrl(signInUrl: string) {
    return {
        type: ActionTypes.SET_SIGN_IN_URL,
        payload: signInUrl,
    } as const;
}

export function setShouldGetPendingPatientsAndClinicalWorkersCounts(
    shouldGetPendingPatientsAndClinicalWorkersCounts: boolean
) {
    return {
        type: ActionTypes.SET_SHOULD_GET_PENDING_PATIENTS_AND_CLINICAL_WORKERS_COUNTS,
        payload: shouldGetPendingPatientsAndClinicalWorkersCounts,
    } as const;
}
