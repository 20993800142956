import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DenyClinicalWorkersPromptModal from './DenyClinicalWorkersPromptModal';

import {
    getPendingClinicalWorkers,
    approveClinicalWorkers,
    denyClinicalWorkers,
} from '../../helpers/services/PendingClinicalWorkersData';
import { ErrorPages, IPendingProvidersTableRows, RoutePath } from '../../models/table.models';
import { setActivePage, setShouldGetPendingPatientsAndClinicalWorkersCounts } from '../../stores/appStore/appActions';
import { analyticsEventNames } from '../../models/consts/analytics';
import { sendAzureEvent } from '../../helpers/appInsights';
import { StatusCode } from '../../models/consts/statusCodes';
import { INotification, NotificationSeverity } from '../../models/app.models';
import { ClinicalWorkersErrorCodes } from '../../models/clinicalWorker.models';
import Button from '../button/Button';
import Loading from '../loading/Loading';
import Notification from '../notification/Notification';
import PendingProvidersTable from '../pendingProvidersTable/PendingProvidersTable';

const tableHead = [
    {
        name: 'table.providerName',
        size: 'large',
    },
    {
        name: 'table.email',
        size: 'large',
    },
    {
        name: 'table.credentials',
        size: 'large',
    },
    {
        name: 'table.npi',
        size: 'small',
    },
];

/**
 * TODO:
 * - Rename PendingProviders to PendingClinicalWorkers
 * - Update i18n key names.
 * - Update PendingProvidersTable to PendingClinicalWorkersTable
 * - Update Provider models
 * - Update Provider helpers & services
 */
const PendingProviders = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState<INotification | undefined>(undefined);
    const [showDenyModal, setShowDenyModal] = useState(false);
    const [selectedClinicalWorkerIds, setSelectedClinicalWorkerIds] = useState<string[]>([]);
    const [clinicalWorkers, setClinicalWorkers] = useState<IPendingProvidersTableRows[]>([]);

    const loadPendingClinicalWorkers = async () => {
        const data = await getPendingClinicalWorkers();

        setClinicalWorkers(data.items);
        setLoading(false);
    };

    useEffect(() => {
        loadPendingClinicalWorkers();
    }, []);

    const getFirstSelectedClinicalWorker = () => {
        return clinicalWorkers.find((clinicalWorker) => clinicalWorker.id === selectedClinicalWorkerIds[0]);
    };

    const handleSelectClinicalWorker = (clinicalWorkerId: string) => {
        sendAzureEvent(analyticsEventNames.CHOOSE_PENDING_PROVIDER);

        setSelectedClinicalWorkerIds(() => {
            const newSetOfSelectedClinicalWorkerIds = [...selectedClinicalWorkerIds];
            const clinicalWorkerIdIndex = selectedClinicalWorkerIds.indexOf(clinicalWorkerId);
            const shouldRemoveClinicalWorkerId = clinicalWorkerIdIndex !== -1;

            if (shouldRemoveClinicalWorkerId) {
                newSetOfSelectedClinicalWorkerIds.splice(clinicalWorkerIdIndex, 1);
            } else {
                newSetOfSelectedClinicalWorkerIds.push(clinicalWorkerId);
            }

            return newSetOfSelectedClinicalWorkerIds;
        });
    };

    const handleRedirect = () => {
        sendAzureEvent(analyticsEventNames.REDIRECT_TO_PROVIDERS);
        history.push(RoutePath.adminProviders);
    };

    const handleApproveOrDenyError = async (errStatus: StatusCode, errCode: string) => {
        if (
            errStatus === StatusCode.CONFLICT &&
            errCode === ClinicalWorkersErrorCodes.ClinicalWorkersClinicAssociationStatusChangeFailedForSome
        ) {
            setNotification({
                message: t('pendingProviders.somethingWentWrongNotification'),
                severity: NotificationSeverity.Warning,
            });
            setSelectedClinicalWorkerIds([]);
            await loadPendingClinicalWorkers();
            dispatch(setShouldGetPendingPatientsAndClinicalWorkersCounts(true));
        } else {
            dispatch(setActivePage(ErrorPages.recall));
        }
    };

    const handleApproveSuccess = async () => {
        let message;
        const isSingleClinicalWorkerApproval = selectedClinicalWorkerIds.length === 1;

        if (isSingleClinicalWorkerApproval) {
            const approvedClinicalWorker = getFirstSelectedClinicalWorker();

            message = t('approveOrDenyNotification.approvedSingleClinicalWorker', {
                first: approvedClinicalWorker?.firstName,
                last: approvedClinicalWorker?.lastName,
            });
        } else {
            message = t('approveOrDenyNotification.approvedMultipleClinicalWorkers');
        }

        setNotification({
            message,
            severity: NotificationSeverity.Success,
        });

        setSelectedClinicalWorkerIds([]);
        await loadPendingClinicalWorkers();
        dispatch(setShouldGetPendingPatientsAndClinicalWorkersCounts(true));
    };

    const handleApprove = async () => {
        setLoading(true);
        sendAzureEvent(analyticsEventNames.CLICK_APPROVE_PROVIDERS);

        try {
            await approveClinicalWorkers(selectedClinicalWorkerIds);
        } catch (err: any) {
            handleApproveOrDenyError(err.response?.status, err.response?.data?.error?.code);

            return;
        }

        handleApproveSuccess();
    };

    const handleDenySuccess = async () => {
        let message;

        const isSingleClinicalWorkerSelected = selectedClinicalWorkerIds.length === 1;

        if (isSingleClinicalWorkerSelected) {
            const deniedClinicalWorker = getFirstSelectedClinicalWorker();

            message = t('approveOrDenyNotification.deniedSingleClinicalWorker', {
                first: deniedClinicalWorker?.firstName,
                last: deniedClinicalWorker?.lastName,
            });
        } else {
            message = t('approveOrDenyNotification.deniedMultipleClinicalWorker');
        }

        setNotification({
            message,
            severity: NotificationSeverity.Success,
        });

        setSelectedClinicalWorkerIds([]);
        await loadPendingClinicalWorkers();
        dispatch(setShouldGetPendingPatientsAndClinicalWorkersCounts(true));
    };

    const handleDeny = async () => {
        setShowDenyModal(false);
        setLoading(true);

        sendAzureEvent(analyticsEventNames.CLICK_DENY_PROVIDERS);

        try {
            await denyClinicalWorkers(selectedClinicalWorkerIds);
        } catch (err: any) {
            handleApproveOrDenyError(err.response?.status, err.response?.data?.error?.code);

            return;
        }

        handleDenySuccess();
    };

    const handleDenyModalClose = () => {
        setShowDenyModal(false);
    };

    const handleDenyModalOpen = () => {
        setShowDenyModal(true);
    };

    return (
        <>
            <div className="main-content patientDirectory">
                <h1 className="main-content__title">{t('pendingProviders.title')}</h1>

                <p className="main-content__description">{t('pendingProviders.description')}</p>

                <div className="patientDirectory__search">
                    <div className="patientDirectory__search-right">
                        <div>
                            <Button
                                className={`btn btn_white ${!selectedClinicalWorkerIds.length ? 'btn_disabled' : ''}`}
                                text={t('deny')}
                                dataTestId="denyProvider"
                                onClick={handleDenyModalOpen}
                            />
                        </div>

                        <div>
                            <Button
                                className={`btn ${!selectedClinicalWorkerIds.length ? 'btn_disabled' : ''}`}
                                text={t('approve')}
                                dataTestId="approveProvider"
                                onClick={handleApprove}
                            />
                        </div>
                    </div>
                </div>

                {loading ? (
                    <Loading />
                ) : (
                    <div>
                        <PendingProvidersTable
                            columns={tableHead}
                            rows={clinicalWorkers}
                            activeRows={selectedClinicalWorkerIds}
                            onChoose={handleSelectClinicalWorker}
                            onRedirect={handleRedirect}
                        />
                    </div>
                )}
            </div>

            <DenyClinicalWorkersPromptModal
                clinicalWorkers={clinicalWorkers}
                isOpen={showDenyModal}
                onClose={handleDenyModalClose}
                onOkClick={handleDeny}
                selectedClinicalWorkerIds={selectedClinicalWorkerIds}
            />

            <Notification applyHomeHeaderOffset applySidebarOffset data={notification} />
        </>
    );
};

export default PendingProviders;
