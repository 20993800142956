enum ActionTypesSite {
    DEFAULT_TYPE = 'DEFAULT_TYPE',
    INCREMENT_PDM_UPLOAD_FAIL_COUNT = 'INCREMENT_PDM_UPLOAD_FAIL_COUNT',
    ROWS_PER_PAGE = 'ROWS_PER_PAGE',
    SAVE_OKTA_DATA = 'SAVE_OKTA_DATA',
    SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE',
    SET_AGREEMENT_STATUS = 'SET_AGREEMENT_STATUS',
    SET_CLINIC_ID = 'SET_CLINIC_ID',
    SET_CURRENT_USER = 'SET_CURRENT_USER',
    SET_ROLE = 'SET_ROLE',
    SET_SIGN_IN_URL = 'SET_SIGN_IN_URL',
    SET_SHOULD_GET_PENDING_PATIENTS_AND_CLINICAL_WORKERS_COUNTS = 'SET_SHOULD_GET_PENDING_PATIENTS_AND_CLINICAL_WORKERS_COUNTS',
}

const ActionTypes = { ...ActionTypesSite };

export default ActionTypes;
